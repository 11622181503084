import { TicketingLink, getTicketingHref } from "@components/TicketingLink";
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetTrigger,
} from "@components/ui/Sheet";
import { simplifyAddress, usernameFallback } from "@utils/index";
import { format } from "date-fns";
import { LogIn, LogOut, Menu, Settings, User2 } from "lucide-react";
import { signOut, useSession } from "next-auth/react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useRouter } from "next/router";
import React, { useEffect, useImperativeHandle } from "react";
import OpenTicketLogo from "./OpenTicketLogo";
import { Skeleton } from "./Skeleton";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/Avatar";
import { Button, buttonVariants } from "./ui/Button";
import { Separator } from "./ui/Separator";

import { useTheme } from "@hooks/theme";
import { useWagmi } from "@hooks/useWagmi";
import { cn } from "@lib/utils";
import { trpc } from "@utils/trpc";
import OpenTicketLogoCompact from "./OpenTicketLogoCompact";
import { useTranslations } from "next-intl";
import { LanguageSelector } from "./LanguageSelector";
import { SearchComponentModal } from "./SearchComponent";
import { CollaboratorButton } from "./CollaboratorButton";
import * as Sentry from "@sentry/browser";

export type HeaderHandle = {
  connectWallet: () => void;
};

type NavbarTitleProps = {
  title: string;
  href: string;
};
const DarkModeToggle = () => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleTheme } = useTheme();

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={handleTheme}
      onKeyDown={({ key }) => {
        if (key === "Enter" || key === " ") handleTheme();
      }}
      className="js-dark-mode-trigger group flex h-10 w-10 items-center justify-center rounded-full border border-stone-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
        className="dark-mode-light h-4 w-4 fill-jacarta-800 transition-colors group-hover:fill-white group-focus:fill-white dark:hidden"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M11.38 2.019a7.5 7.5 0 1 0 10.6 10.6C21.662 17.854 17.316 22 12.001 22 6.477 22 2 17.523 2 12c0-5.315 4.146-9.661 9.38-9.981z" />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
        className="dark-mode-dark hidden h-4 w-4 fill-jacarta-800 transition-colors group-hover:fill-white group-focus:fill-white dark:block dark:fill-white"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M12 18a6 6 0 1 1 0-12 6 6 0 0 1 0 12zM11 1h2v3h-2V1zm0 19h2v3h-2v-3zM3.515 4.929l1.414-1.414L7.05 5.636 5.636 7.05 3.515 4.93zM16.95 18.364l1.414-1.414 2.121 2.121-1.414 1.414-2.121-2.121zm2.121-14.85l1.414 1.415-2.121 2.121-1.414-1.414 2.121-2.121zM5.636 16.95l1.414 1.414-2.121 2.121-1.414-1.414 2.121-2.121zM23 11v2h-3v-2h3zM4 11v2H1v-2h3z" />
      </svg>
    </div>
  );
};

const NavbarTitle = ({ title, href }: NavbarTitleProps) => (
  <li className="js-nav-dropdown group relative">
    <TicketingLink
      href={href}
      className="flex items-center justify-between py-3.5 font-display text-base text-card-foreground hover:text-accent focus:text-accent dark:text-white dark:hover:text-accent dark:focus:text-accent lg:px-5"
    >
      {title}
      <i className="lg:hidden">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          className="h-4 w-4 dark:fill-white"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
        </svg>
      </i>
    </TicketingLink>
  </li>
);
const ICON_HEIGHT = 45;

const TicketingHeader = React.forwardRef<
  HeaderHandle,
  React.ComponentProps<"header">
>((props, ref) => {
  const t = useTranslations("Ticketing.components.Header");
  const term = useTranslations("Terms");
  const { data: account } = useSession();
  const user = trpc.user.get.useQuery(
    { address: account?.address ?? "" },
    { enabled: !!account?.address }
  );
  const router = useRouter();
  const { data } = trpc.collaborators.getOnboardingStatus.useQuery(undefined, {
    refetchInterval: (data) =>
      !!account && !data?.isOnboardingComplete ? 10000 : false,
    enabled: !!account,
  });

  useEffect(() => {
    if (account && account.user && account.user.email && account.user.name) {
      Sentry.setUser({
        email: account.user.email,
        username: account.user.name,
      });
    }
    const ignoredRoutes = [
      "/profile/update",
      getTicketingHref("/terms-of-service"),
      getTicketingHref("/privacy-policy"),
      getTicketingHref("/refund-policy"),
    ];
    if (
      account &&
      !account.profileComplete &&
      !ignoredRoutes.includes(router.pathname)
    ) {
      void router.push(
        `/profile/update?redirect=${encodeURIComponent(
          window.location.href
        )}&profileIncomplete=true`
      );
    }
  }, [account, router]);
  const { wagmi } = useWagmi();
  const pathname = usePathname();
  const { skeletonTheme } = useTheme();
  useImperativeHandle(ref, () => {
    return {
      connectWallet: () => {
        return;
      },
    };
  });
  const showCollaboratorButton =
    // Is logged in
    !!user.data &&
    // Not an organizer
    (!user.data?.organizer ||
      // Or organizer with onboarding not complete
      (user.data?.organizer &&
        user.data.organizer.onboardingStatus !== "complete"));

  return (
    <>
      <header
        {...props}
        className={cn(
          "relative z-40 flex w-auto items-center justify-between bg-card p-4 ring-2 ring-ring lg:m-2 lg:rounded-md",
          props.className
        )}
      >
        <TicketingLink href="/" className="shrink-0">
          <OpenTicketLogo
            height={ICON_HEIGHT}
            className="hidden fill-black dark:fill-stone-100 lg:block"
          />
          <OpenTicketLogoCompact
            height={ICON_HEIGHT}
            className="block fill-black dark:fill-stone-100 lg:hidden"
          />
        </TicketingLink>

        <ul className="absolute right-[50%] hidden translate-x-1/2 list-none lg:flex">
          <NavbarTitle title={term("home")} href="/" />
          <NavbarTitle
            title={term("event", { type: "plural" })}
            href="/events"
          />
          <NavbarTitle title={t("about")} href="/?force=true" />
          <NavbarTitle
            title={term("metaverse")}
            href="https://www.spatial.io/s/Open-Ticket-Spaceship-64ba554ce20b6d94c1df250b?share=6713915543840410238"
          />
        </ul>

        <div className="space-x-2 lg:pr-5">
          <div className="flex">
            <SearchComponentModal shouldManipulateRoute={false} />
            <DarkModeToggle />
            {!wagmi && (
              <div className="ml-2 flex h-10 w-10 overflow-hidden rounded-full bg-black/5 dark:bg-white/10">
                <Skeleton {...skeletonTheme} height={40} width={40} />
              </div>
            )}
            {wagmi && (
              <Sheet>
                <SheetTrigger asChild>
                  <Button
                    size="icon"
                    variant="ghost"
                    className="ml-2 rounded-full"
                  >
                    {account?.address ? (
                      <Avatar className="flex h-10 w-10 items-center justify-center rounded-full border border-stone-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent">
                        {account.user?.image && (
                          <AvatarImage src={account.user.image} />
                        )}
                        <AvatarFallback>
                          {usernameFallback({
                            address: account.address,
                            name: account.user?.name ?? "",
                          })}
                        </AvatarFallback>
                      </Avatar>
                    ) : (
                      <>
                        <div className="relative flex min-h-[2.5rem] min-w-[2.5rem] items-center justify-center rounded-full border border-stone-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent">
                          <Link
                            href={`/login${
                              pathname
                                ? `?redirect=${encodeURIComponent(pathname)}`
                                : ""
                            }`}
                            className="absolute inset-0 hidden h-full w-full items-center justify-center lg:flex"
                          >
                            <LogIn size={20} className="mr-1" />
                          </Link>
                          <Menu className="block lg:hidden" />
                        </div>
                      </>
                    )}
                  </Button>
                </SheetTrigger>

                <SheetContent className="min-w-screen flex flex-col">
                  {account && account.address ? (
                    <>
                      <div className="flex w-full flex-col items-stretch space-y-3">
                        <TicketingLink
                          href={`/user/me`}
                          className="text-card-foreground dark:text-white"
                        >
                          <div className="flex gap-4">
                            <Avatar className="h-14 w-14 border-2 border-accent">
                              <AvatarImage
                                src={account.user?.image ?? user.data?.imageUrl}
                                height={56}
                                width={56}
                              />
                              <AvatarFallback>
                                {usernameFallback({
                                  address: account.address,
                                  name: account.user?.name ?? "",
                                })}
                              </AvatarFallback>
                            </Avatar>
                            {user.data && (
                              <div className="flex flex-col justify-center">
                                <h2>
                                  {account?.user?.name ||
                                    simplifyAddress(user.data.address)}
                                </h2>
                                <small>
                                  {term("joined")}{" "}
                                  {format(user.data.createdAt, "PP")}
                                </small>
                              </div>
                            )}
                          </div>
                        </TicketingLink>
                        {account.isOrganizer &&
                          data &&
                          data.isOnboardingComplete && (
                            <Button asChild>
                              <TicketingLink
                                href={getTicketingHref(`/organizer`)}
                              >
                                {t("organizer")}
                              </TicketingLink>
                            </Button>
                          )}
                        <Separator />

                        <Button
                          variant={"ghost"}
                          size={"sm"}
                          className="justify-center space-x-2 text-card-foreground hover:text-accent dark:text-white dark:hover:text-accent"
                          asChild
                        >
                          <TicketingLink href={"/user/me"}>
                            <User2 size={16} className="mt-[2px]" />
                            <span>{term("profile")}</span>
                          </TicketingLink>
                        </Button>
                        <Button
                          variant={"ghost"}
                          size={"sm"}
                          className="justify-center space-x-2 text-card-foreground hover:text-accent dark:text-white dark:hover:text-accent"
                          asChild
                        >
                          <Link
                            href={`/profile/update?redirect=${encodeURIComponent(
                              pathname
                            )}`}
                          >
                            <Settings size={16} className="mt-[2px]" />
                            <span>{term("edit")}</span>
                          </Link>
                        </Button>
                        <Button
                          variant={"ghost"}
                          size={"sm"}
                          className="justify-center space-x-2 text-card-foreground hover:text-accent focus:text-accent dark:text-white dark:hover:text-accent dark:focus:text-accent"
                          onClick={async () => {
                            await signOut({ redirect: true, callbackUrl: "/" });
                            await wagmi.core.disconnect();
                            localStorage.clear();
                            sessionStorage.clear();
                          }}
                        >
                          <LogOut size={16} className="mt-[2px]" />
                          <span>{term("logout")}</span>
                        </Button>
                      </div>
                      <Separator />
                    </>
                  ) : (
                    <div>
                      <Link
                        href={`/login${
                          pathname
                            ? `?redirect=${encodeURIComponent(pathname)}`
                            : ""
                        }`}
                        className={cn(
                          buttonVariants(),
                          "mt-5 flex w-full items-center gap-2"
                        )}
                      >
                        <LogIn size={16} className="" />
                        <span>{term("login")}</span>
                      </Link>
                    </div>
                  )}
                  <div className="flex w-full flex-col text-center">
                    <TicketingLink
                      href="/"
                      className="py-3.5 font-display text-base text-card-foreground hover:text-accent focus:text-accent dark:text-white dark:hover:text-accent dark:focus:text-accent"
                    >
                      {term("home")}
                    </TicketingLink>
                    <TicketingLink
                      href="/events"
                      className="py-3.5 font-display text-base text-card-foreground hover:text-accent focus:text-accent dark:text-white dark:hover:text-accent dark:focus:text-accent"
                    >
                      {term("event", { type: "plural" })}
                    </TicketingLink>

                    <TicketingLink
                      href="/?force=true"
                      className="py-3.5 font-display text-base text-card-foreground hover:text-accent focus:text-accent dark:text-white dark:hover:text-accent dark:focus:text-accent"
                    >
                      {t("about")}
                    </TicketingLink>

                    <TicketingLink
                      href="https://www.spatial.io/s/Open-Ticket-Spaceship-64ba554ce20b6d94c1df250b?share=6713915543840410238"
                      className="py-3.5 font-display text-base text-card-foreground hover:text-accent focus:text-accent dark:text-white dark:hover:text-accent dark:focus:text-accent"
                    >
                      {term("metaverse")}
                    </TicketingLink>
                    {showCollaboratorButton && (
                      <CollaboratorButton
                        onboardingStatus={
                          user.data?.organizer?.onboardingStatus
                        }
                      />
                    )}
                    <Separator className="my-3" />
                    <div className="flex justify-center">
                      <LanguageSelector className="-ml-1" />
                    </div>
                  </div>

                  <SheetFooter className="mt-auto flex w-full flex-row !justify-between">
                    <DarkModeToggle />
                  </SheetFooter>
                </SheetContent>
              </Sheet>
            )}
          </div>
        </div>
      </header>
    </>
  );
});
TicketingHeader.displayName = "TicketingHeader";
export { TicketingHeader };
